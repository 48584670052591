import ImageIcon from '@material-ui/icons/Image'
import TimeIcon from '@material-ui/icons/AccessTime'
import GridViewIcon from '@material-ui/icons/GridOn'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

const NFT_DROP_CONTRACT_NAME = 'SIMBAERC721FinalV3';

const NAVIGATION_TABS = {
  DROP: {
    id: 'DROP',
    label: 'NFT Drop',
    value: '/drop',
    children: {
      CONNECT: {
        id: 'CONNECT',
        label: 'Connect',
        value: '/drop/connect',
        icon: <TimeIcon />
      },
      MINT: {
        id: 'MINT',
        label: 'MINT',
        value: '/drop/mint',
        icon: <ImageIcon />
      },
      EVENTS: {
        id: 'EVENTS',
        label: 'NFT Events',
        value: '/drop/events',
        icon: <CloudUploadIcon />
      },
      URI: {
        id: 'URI',
        label: 'TOKEN URI',
        value: '/drop/uri',
        icon: <TimeIcon />
      },
      ROLE: {
        id: 'ROLE',
        label: 'Role',
        value: '/drop/role',
        icon: <TimeIcon />
      },
      LIBRARY: {
        id: 'LIBRARY',
        label: 'Library',
        value: '/drop/library',
        icon: <GridViewIcon />
      }
    }
  },
  MARKETPLACE: {
    id: 'MARKETPLACE',
    label: 'NFT Marketplace',
    value: '/marketplace',
    children: {
      CONNECT: {
        id: 'CONNECT',
        label: 'Connect',
        value: '/marketplace/connect',
        icon: <TimeIcon />
      },
      REGISTER_COLLECTION: {
        id: 'REGISTER_COLLECTION',
        label: 'Register Collection',
        value: '/marketplace/register-collection',
        icon: <TimeIcon />
      },
      ASSETS: {
        id: 'ASSETS',
        label: 'Assets',
        value: '/marketplace/assets',
        icon: <TimeIcon />
      }
    }
  },
  MDR: {
    id: 'MDR',
    label: 'NFT MDR',
    value: '/mdr',
    children: {
      REGISTER_TO_MDR: {
        id: 'REGISTER_TO_MDR',
        label: 'Register To MDR',
        value: '/mdr/register-to-mdr',
        icon: <TimeIcon />
      },
      MDR: {
        id: 'MDR',
        label: 'MDR',
        value: '/mdr/mdr',
        icon: <ImageIcon />
      },
      MDR_URI: {
        id: 'MDR_URI',
        label: 'MDR Uri',
        value: '/mdr/mdr-uri',
        icon: <TimeIcon />
      },
      MDR_EVENTS: {
        id: 'MDR_EVENTS',
        label: 'MDR Events',
        value: '/mdr/mdr-events',
        icon: <TimeIcon />
      }
    }
  }
}

const NFT_DROP_EVENT_NAME = 'Transfer';

const LEFT_SIDEBAR_ITEMS = [
  {
      id: 0,
      hasCaret: true,
      isExpanded: true,
      label: "Getting Started",
      childNodes: [
          {
              id: 1,
              label: "The NFT Block",
              link: "/docs/introduction"
          },
          {
              id: 2,
              label: "Blocks",
              link: "/docs/Blocks"
          },
          {
              id: 3,
              label: "SDK",
              link: "/docs/SDK"
          }

      ]
  },
  {
      id: 4,
      hasCaret: true,
      isExpanded: true,
      label: "Block Introductions",
      childNodes: [
        {
          id: 5,
          label: "Mint",
          link: "/docs/Mint_Docs"
        },
        {
          id: 6,
          label: "Marketplace",
          link: "/docs/Marketplace_Docs"
        },
        {
          id: 7,
          label: "Metadata Registry",
          link: "/docs/MDR_Docs"
        }
      ]
  },
];


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  NAVIGATION_TABS,
  NFT_DROP_CONTRACT_NAME,
  NFT_DROP_EVENT_NAME,
  LEFT_SIDEBAR_ITEMS
}