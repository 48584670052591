import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const providerOptions = {
    rpc: {
        1: process.env.CHAINSTACK_API_ID,
        4: process.env.CHAINSTACK_API_ID,
        80001: process.env.CHAINSTACK_API_ID,
        0x13881: process.env.CHAINSTACK_API_ID,
        // ...
    },
    walletlink: {
        package: CoinbaseWalletSDK, // Required
        options: {
            rpc: {
                1: process.env.CHAINSTACK_API_ID,
                4: process.env.CHAINSTACK_API_ID,
                80001: process.env.CHAINSTACK_API_ID,
                // ...
            },
            appName: "Web 3 Modal Demo", // Required
            infuraId: process.env.INFURA_KEY // Required unless you provide a JSON RPC url; see `rpc` below
        }
    }
};
