import constants from '../types'

const initialState = {
    connection: '',
    account: '',
    contract_view_only: '',
    nft_contract: '',
    value: '',
    events: [],
    mdrevents: '',
    marketplaceEvents: [],
    libraryItems: [],
    tokenURI: '',
    tokenURIFromMDR: '',
    nft: '',
    meta: '',
    network: '',
    chainID: '',
    mumbai_web3: '',
    chainstack_web3: '',
    block_number: '',
    contract_read_only_instance: '',
    treasury_contract: '',
    SIMS_contract: '',
    marketplace_contract: '',
    mdr_contract: '',
    registered_collection: '',
    registered_collection_mdr: '',
    nftaddress: '',
    mdraddress: '',
    provider: '',
    signer: '',
    treasury: '',
    blockexplorerurl: '',
    mdrblockexplorerurl: '',
    blockexplorerTx: ''
}

export function connectionReducer(state = initialState, action) {
    //
    switch (action.type) {
        case constants.CONTRACT_READONLY_INSTANCE:
            return Object.assign({}, state, {
                contract_read_only_instance: action.contract_read_only_instance
            })
        case constants.TX_BLOCKEXPLORER:
            return Object.assign({}, state, {
                blockexplorerTx: action.blockexplorerTx
            })

        case constants.MDR_BLOCKEXPLORER_LOADED:
            return Object.assign({}, state, {
                mdrblockexplorerurl: action.mdrblockexplorerurl
            })
        case constants.BLOCKEXPLORER:
            return Object.assign({}, state, {
                blockexplorerurl: action.blockexplorerurl
            })
        case constants.MUMBAI_NETWORK_LOADED:
            return Object.assign({}, state, {
                mumbai_web3: action.mumbai_web3
            })
        case constants.CHAINSTACK_NETWORK_LOADED:
            return Object.assign({}, state, {
                chainstack_web3: action.chainstack_web3
            })
        case constants.NFT_CONTRACT_ADDRESS_LOADED:
            return Object.assign({}, state, {
                nftaddress: action.nftaddress
            })
        case constants.MDR_EVENTS_LOADED:
            return Object.assign({}, state, {
                mdrevents: action.mdrevents
            })
        case constants.MARKETPLACE_EVENTS_LOADED:
            return Object.assign({}, state, {
                marketplaceEvents: action.marketplaceEvents
            })
        case constants.MDR_CONTRACT_ADDRESS_LOADED:
            return Object.assign({}, state, {
                mdraddress: action.mdraddress
            })
        case constants.BLOCKNUMBER_LOADED:
            return Object.assign({}, state, {
                block_number: action.block_number
            })
        case constants.GET_TREASURY:
            return Object.assign({}, state, {
                treasury: action.treasury
            })
        case constants.NETWORK_LOADED:
            return Object.assign({}, state, {
                network: action.network
            })
        case constants.CHAIN_ID_LOADED:
            return Object.assign({}, state, {
                chainID: action.chainID
            })
        case constants.PROVIDER_LOADED:
            return Object.assign({}, state, {
                provider: action.provider
            })
        case constants.SIGNER_LOADED:
            return Object.assign({}, state, {
                signer: action.signer
            })
        case constants.EVENTS_LOADED:
            //
            return Object.assign({}, state, {
                events: action.events
            })
        case constants.LIBRARY_LOADED:
            return Object.assign({}, state, {
                libraryItems: action.libraryItems
            })
        case constants.CONTRACT_VIEW_LOADED:
            // 
            return Object.assign({}, state, {
                contract_view_only: action.contract_view_only
            })
        case constants.NFT_CONTRACT_LOADED:
            // 
            return Object.assign({}, state, {
                nft_contract: action.nft_contract
            })
        case constants.MDR_CONTRACT_LOADED:
            // 
            return Object.assign({}, state, {
                mdr_contract: action.mdr_contract
            })
        case constants.TREASURY_CONTRACT_LOADED:
            //
            return Object.assign({}, state, {
                treasury_contract: action.treasury_contract
            })
        case constants.SIMS_CONTRACT_LOADED:
            //
            return Object.assign({}, state, {
                SIMS_contract: action.SIMS_contract
            })
        case constants.MARKETPLACE_CONTRACT_LOADED:
            //
            return Object.assign({}, state, {
                marketplace_contract: action.marketplace_contract
            })
        case constants.REGISTERED_COLLECTION:
            // 
            return Object.assign({}, state, {
                registered_collection: action.registered_collection
            })
        case constants.REGISTERED_COLLECTION_MDR:
            // 
            return Object.assign({}, state, {
                registered_collection_mdr: action.registered_collection
            })
        case constants.NFT_MINTED:
            //
            return Object.assign({}, state, {
                nft: action.nft
            })
        case constants.MDR_SUBMISSION:
            //
            return Object.assign({}, state, {
                meta: action.meta
            })
        case constants.IMAGE_UPDATED:
            return Object.assign({}, state, {
                imageUpdated: action.imageUpdated
            })
        case constants.FETCH_TOKENURI:
            // 
            return Object.assign({}, state, {
                tokenURI: action.tokenURI
            })
        case constants.FETCH_TOKENURI_MDR:
            // 
            return Object.assign({}, state, {
                tokenURIFromMDR: action.tokenURI
            })
        case constants.VALUE_LOADED:
            return Object.assign({}, state, {
                value: action.value
            })
        case constants.CONNECTION_LOADED:
            //
            return Object.assign({}, state, {
                connection: action.connection
            })

        case constants.ACCOUNT_LOADED:
            return Object.assign({}, state, {
                account: action.account
            })
        default:
            return state
    }
}
