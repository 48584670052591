import * as ROUTES from "../../configs/Routes";
import {
    Alignment,
    AnchorButton,
    Classes,
    Button,
    H5,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
} from "@blueprintjs/core";
import { React } from "react";
import { Route } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ListIcon from '@material-ui/icons/List';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import FolderIcon from '@material-ui/icons/Folder';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import './styles.scss'

export const TitleApp = () => {
    return (
        <Route render={({ history }) => (
            <Breadcrumbs separator="|">
                <Link
                    onClick={() => { history.push('/docs/introduction') }}
                    className="link">
                    <Typography className="linkicon"  >
                        Home
                    </Typography>

                </Link>
                <Link
                    onClick={() => { history.push('/drop/mint') }}
                    className="link">
                    <Typography className="linkicon"  >
                        Create NFT
                    </Typography>
                </Link>
                <Link
                    onClick={() => { history.push('/mdr/register-to-mdr') }}
                    className="link"
                >
                    <Typography className="linkicon"  >
                        Register Metadata
                    </Typography>

                </Link>
            </Breadcrumbs >
        )} />
    );
}
/*

                <Link
                    onClick={() => { history.push('/marketplace/register-collection') }}
                    className="link"
                >
                    <Typography className="linkicon"  >
                        Create Marketplace
                    </Typography>
                </Link>
                <Link
                    onClick={() => { history.push('/mdr/register-to-mdr') }}
                    className="link"
                >
                    <Typography className="linkicon"  >
                        Register Metadata
                    </Typography>

                </Link>*/