import React, {useEffect} from "react";
import TreeLink from "../TreeLink";
import { withRouter } from "react-router-dom";
import constants from "../../configs/constants";
import style from "../../configs/theme/demo.module.css";

const LeftSidebar = props => {
    useEffect(() => {
      props.onChange && props.onChange();
    }, [props.location.pathname]);

    constants.LEFT_SIDEBAR_ITEMS.map(i =>
        i.childNodes.map(
            child =>
                child.link === props.location.pathname
                    ? (child.isSelected = true) & !!i.isExpanded
                    : (child.isSelected = false) /* & (i.icon = "folder-close")*/
        )
    );

    return (

        <TreeLink
            contents={constants.LEFT_SIDEBAR_ITEMS}
            color="primary"
            className={style["leftSidebar"]}
        />

    );
};

export default withRouter(LeftSidebar);
