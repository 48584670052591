import { create as ipfsHttpClient } from 'ipfs-http-client'
import React, { useState, useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '../../components/Alert'
import { Add, Fab, Slide, Button, Snackbar, Icon, Dialog, Grid, Table, TableBody, Toolbar, IconButton, TableCell, TableHead, TableRow, TextField, Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from '@material-ui/core/CircularProgress';
import { submitMetadata } from "../../core/interactions/interactions";
import { Form, Label } from '../../components/Form';
import { TitleMDR } from "../../components/TitleMDR"
import { styles } from './styles.scss'

const client = ipfsHttpClient('https://ipfs.infura.io:5001')

// const currencies = [
//   {
//     value: 0,
//     label: 'WETH',
//   },
//   {
//     value: 1,
//     label: 'MATIC',
//   },
//   {
//     value: 2,
//     label: 'SIMS',
//   }
// ];

const MDRView = ({ classes }) => {
  const dispatch = useDispatch();
  const [collectionAddress, setCollectionAddress] = useState('');
  const [description, setDescription] = useState('');
  const [metadataName, setMetadataName] = useState('');
  // const [paymentMethod, setPaymentMethod] = useState(1); // start with MATIC state
  const [tokenId, setTokenId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const mdr_contract = useSelector(state => state.connection.mdr_contract);
  const account = useSelector(state => state.connection.account);
  const [showDialog, setShowDialog] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [mined, setMined] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const blockexplorerTx = useSelector(state => state.connection.blockexplorerTx);

  const handleMDRSubmission = useCallback(async (asset) => {
    asset.preventDefault();
    setShowSign(true);
    setShowDialog(true);
    setMined(false);
    try {
      const added = await client.add(
        selectedFile,
        {
          progress: (prog) => console.log(`received: ${prog}`)
        }
      )
      const url = `https://ipfs.infura.io/ipfs/${added.path}`
      console.log('New IPFS URL', url);

      const res = await submitMetadata(
        dispatch,
        mdr_contract,
        collectionAddress,
        selectedFile,
        description,
        url,
        metadataName,
        tokenId,
        // paymentMethod
      )

      //await res.wait();
      setMined(true);
      setTransactionHash(res.hash);
      console.log(res.hash)
    } catch (error) {
      console.log('Error uploading file: ', error);
    }
  }, [mdr_contract, dispatch, collectionAddress, selectedFile, description, metadataName, tokenId]);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowDialog(false);
    setShowSign(false);
    setMined(false);
  };

  const SentAlert = (e) => {
    return <Snackbar open={showDialog} onClose={handleClose}>
      <Alert onClose={handleClose} severity="info">Please wait while we confirm your request...</Alert>
    </Snackbar>
  }

  const SuccessAlert = (e) => {

    return <Snackbar open={mined} autoHideDuration={10000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        Your request has been approved and is on the blockchain.<br />
        <a target="_blank" href={blockexplorerTx + transactionHash}>View on a block explorer</a>
      </Alert>
    </Snackbar>
  }


  return <div className="container"  >
    <Grid container
      direction="column">
      <Grid direction="column" >
        <TitleMDR></TitleMDR>
      </Grid>
      <Grid direction="column" >
        <form className="form">
          <Grid justifyContent="space-between" direction="column">
            <TextField
              style={{ width: '100%' }}
              label="Please enter NFT collection address"
              className="textField"
              value={collectionAddress}
              onChange={e => setCollectionAddress(e.target.value)}
              margin="normal"
            />
            <Grid justifyContent="space-between" direction="column">
              <TextField
                style={{ width: '100%' }}
                label="Please enter tokenId"
                className="textField"
                value={tokenId}
                onChange={e => setTokenId(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid justifyContent="space-between" direction="column">
              <TextField
                style={{ width: '100%' }}
                label="Please enter metadata name"
                className="textField"
                inputProps={{ maxLength: 32 }}
                value={metadataName}
                onChange={e => setMetadataName(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid justifyContent="space-between" direction="column">
              <TextField
                style={{ width: '100%' }}
                label="Please enter description"
                className="textField"
                multiline
                maxRows={4}
                inputProps={{ maxLength: 128 }}
                value={description}
                onChange={e => setDescription(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs container direction="column" >
              <Button style={{
                backgroundColor: "#e7e9ea",
              }} variant="contained" component="label" >
                {" "}
                <AddIcon /> Upload a file
                <input type="file" hidden name="NFT"
                  onChange={(e) => setSelectedFile(e.target.files[0])} />
              </Button>
            </Grid>
            <Grid item xs container direction="column" >                                                                                                             {/* limit payments to Matic for now */}
              <Button
                onClick={handleMDRSubmission}
                disabled={!selectedFile || !metadataName || !description || !tokenId || !collectionAddress}
                variant="contained"
                style={{
                  backgroundColor: "#e7e9ea",
                }}
                className="my-4"
              >
                Backup NFT
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <SentAlert />
      <SuccessAlert />
    </Grid>
  </div >;
}

export default withStyles(styles)(MDRView)