/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { setMinter } from "../../core/interactions_blocks";
import { nftcontractSelector, accountSelector, valueSelector, eventSelector, connectionSelector, blockexplorerTxSelector } from "../../core/selectors/selectors";
import { Button, Snackbar, Grid, TextField } from "@material-ui/core";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import constants from '../../configs/constants'
import { Alert } from '../../components/Alert'
import { TitleMint } from "../../components/TitleMint"

class RoleView extends Component {
  constructor(props) {
    super(props)
    this.SentAlert = this.SentAlert.bind(this);
    this.SuccessAlert = this.SuccessAlert.bind(this);
    this.state = {
      new_minter_id: '',
      showDialog: false,
      mined: false,
      transactionHash: '',
      setShowDialog: false,
      showSign: false
    }
  }
  componentDidMount() {
    const {
      actions, asset
    } = this.props
    console.log('this props provider', actions)
    console.log('Asset props is mounted', asset)

  }

  handleApproveClick = async (evt) => {
    evt.preventDefault();
    const { connection, dispatch, nft_contract, account, value, events, modalState } = this.props;
    this.setState({
      mined: false,
      showDialog: true,
      showSign: true
    });
    const formValue = await evt.currentTarget.form[0].value
    try {
      // await nft_contract.address;
      const res = await setMinter(constants.NFT_DROP_CONTRACT_NAME, formValue);
      console.log('res', res)
      this.setState({
        mined: true,
        transactionHash: res.transaction_hash
      });
      console.log(res.hash)
    } catch (error) {
      console.log('error:', error)
    }
  }

  handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showDialog: false,

    })
  };

  handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showDialog: false,
      mined: false,

    })
  };

  SentAlert = (e) => {
    const {
      showDialog,
      mined,
      transactionHash,
      showSign,
    } = this.state
    const { blockexplorerTx } = this.props;

    return <Snackbar open={showDialog} onClose={this.handleClose1}>
      <Alert onClose={this.handleClose1} severity="info">Please wait while we confirm your request...</Alert>
    </Snackbar >
  }

  SuccessAlert = (e) => {
    const {
      showDialog,
      mined,
      transactionHash,
      showSign,
    } = this.state
    const { blockexplorerTx } = this.props;
    return <Snackbar open={mined} autoHideDuration={10000} onClose={this.handleClose2}>
      <Alert onClose={this.handleClose2} severity="success">
        Your request has been approved and is on the blockchain.<br />
        <a target="_blank" href={blockexplorerTx + transactionHash}>View on a block explorer</a>
      </Alert>
    </Snackbar >
  }
  render() {
    return (

      <div className="container">
        <Grid container
          direction="column">
          <Grid direction="column">
            <TitleMint></TitleMint>
          </Grid>
          <Grid direction="column">
            <form className="form">
              <Grid justifyContent="space-between" direction="column">
                <TextField
                  id="outlined-required"
                  style={{ width: '100%' }}
                  label="Wallet Address"
                  margin="normal"
                />
              </Grid>
              <Grid item xs container direction="column" >
                <Button
                  onClick={this.handleApproveClick}
                  variant="contained"
                  style={{
                    backgroundColor: "#e7e9ea",
                  }}>
                  Approve Address
                </Button>
              </Grid>
            </form>
          </Grid>

          <this.SentAlert />
          <this.SuccessAlert />
        </Grid>
      </div>

    )
  }
}
function mapStateToProps(state) {
  return {
    connection: connectionSelector(state),
    nft_contract: nftcontractSelector(state),
    account: accountSelector(state),
    events: eventSelector(state),
    value: valueSelector(state),
    blockexplorerTx: blockexplorerTxSelector(state)
  }
}

export default withRouter(connect(mapStateToProps)(RoleView))
