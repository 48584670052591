import keyMirror from 'keymirror'

export default keyMirror({
  ADD_ASSET: null,
  ASSET_ERROR: null,
  CHECK_ASSET: null,
  CLEAR_ACCOUNT: null,
  CLEAR_ASSETS: null,
  CLEAR_UI: null,
  CLOSE_LEFT_NAV: null,
  OPEN_MODAL: null,
  CLOSE_MODAL: null,
  CHAIN_ID_LOADED: null,
  NETWORK_LOADED: null,
  OPEN_CONFIRM_MODAL: null,
  CLOSE_CONFIRM_MODAL: null,
  CREATE_ASSET_HASH: null,
  CONTRACT_READONLY_INSTANCE: null,
  OPEN_LEFT_NAV: null,
  SET_ACCOUNT: null,
  FETCH_TOKENURI: null,
  FETCH_TOKENURI_MDR: null,
  SET_MINTER: null,
  MDR_BLOCKEXPLORER_LOADED: null,
  MDR_EVENTS_LOADED: null,
  MARKETPLACE_EVENTS_LOADED: null,
  TX_BLOCKEXPLORER: null,
  BLOCKEXPLORER: null,
  CREATE_MINTER: null,
  REGISTERED_COLLECTION: null,
  REGISTERED_COLLECTION_MDR: null,
  SET_ACCOUNT_EMAIL: null,
  CONNECTION_LOADED: null,
  ACCOUNT_LOADED: null,
  BLOCKNUMBER_LOADED: null,
  CHAINSTACK_NETWORK_LOADED: null,
  MUMBAI_NETWORK_LOADED: null,
  NFT_CONTRACT_ADDRESS_LOADED: null,
  MDR_CONTRACT_ADDRESS_LOADED: null,
  CONTRACT_VIEW_LOADED: null,
  PROVIDER_LOADED: null,
  SIGNER_LOADED: null,
  NFT_CONTRACT_LOADED: null,
  MDR_CONTRACT_LOADED: null,
  MARKETPLACE_CONTRACT_LOADED: null,
  VALUE_LOADED: null,
  EVENTS_LOADED: null,
  LIBRARY_LOADED: null,
  SIMS_CONTRACT_LOADED: null,
  TREASURY_CONTRACT_LOADED: null,
  NFT_MINTED: null,
  MDR_SUBMISSION: null,
  IMAGE_UPDATED: null,
  GET_TREASURY: null
})
