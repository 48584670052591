import { get } from 'lodash';
import { createSelector } from 'reselect';

// WEB3
const connection = state => get(state, 'connection.connection', null);
export const connectionSelector = createSelector(connection, w => w);

const account = state => get(state, 'connection.account', null);
export const accountSelector = createSelector(account, a => a);

const chainID = state => get(state, 'connection.chainID', null);
export const chainIDSelector = createSelector(chainID, a => a);

const blockexplorerTx = state => get(state, 'connection.blockexplorerTx', null);
export const blockexplorerTxSelector = createSelector(blockexplorerTx, a => a);

const mdrblockexplorerurl = state => get(state, 'connection.mdrblockexplorerurl', null);
export const mdrblockexplorerurlSelector = createSelector(mdrblockexplorerurl, a => a);

const blockexplorerurl = state => get(state, 'connection.blockexplorerurl', null);
export const blockexplorerurlSelector = createSelector(blockexplorerurl, a => a);

const block_number = state => get(state, 'connection.block_number', null);
export const blocknumberSelector = createSelector(block_number, a => a);

//CONTRACT
const nft_contract = state => get(state, 'connection.nft_contract', null);

export const nftcontractSelector = createSelector(nft_contract, a => a);

const mdr_contract = state => get(state, 'connection.mdr_contract', null);

export const mdrcontractSelector = createSelector(mdr_contract, a => a);

const nftaddress = state => get(state, 'connection.nftaddress', null);

export const nftcontractAddressSelector = createSelector(nftaddress, a => a);

const mdraddress = state => get(state, 'connection.mdraddress', null);

export const mdrcontractAddressSelector = createSelector(mdraddress, a => a);

const marketplace_contract = state => get(state, 'connection.marketplace_contract', null);

export const marketplacecontractSelector = createSelector(marketplace_contract, a => a);

const SIMS_contract = state => get(state, 'connection.SIMS_contract', null);

export const SIMScontractSelector = createSelector(SIMS_contract, a => a);

const treasury_contract = state => get(state, 'connection.treasury_contract', null);

export const treasurycontractSelector = createSelector(treasury_contract, a => a);

const contract_view_only = state => get(state, 'connection.contract_view_only', null);

export const contractViewSelector = createSelector(contract_view_only, a => a);

const contract_read_only_instance = state => get(state, 'connection.contract_read_only_instance:', null);
export const contractEventsInstanceSelector = createSelector(contract_read_only_instance, a => a);

const value = state => get(state, 'connection.value', null);
export const valueSelector = createSelector(value, a => a);

const events = state => get(state, 'connection.events', null);
export const eventSelector = createSelector(events, a => a);

const mdrevents = state => get(state, 'connection.mdrevents', null);
export const mdreventSelector = createSelector(mdrevents, a => a);

const tokenURI = state => get(state, 'connection.tokenURI', null);
export const tokenURISelector = createSelector(tokenURI, a => a);

const provider = state => get(state, 'connection.provider', null);
export const providerSelector = createSelector(provider, a => a);

const signer = state => get(state, 'connection.signer', null);
export const signerSelector = createSelector(signer, a => a);
//ui
const modalState = state => get(state, 'ui.modalState.openModal', null);
export const modalStateSelector = createSelector(modalState, a => a);