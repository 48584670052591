import * as ROUTES from "../../configs/Routes";
import {
    Alignment,
    AnchorButton,
    Classes,
    Button,
    H5,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
} from "@blueprintjs/core";
import { React } from "react";
import { Route } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ListIcon from '@material-ui/icons/List';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import FolderIcon from '@material-ui/icons/Folder';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import BackupIcon from '@material-ui/icons/Backup';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

function handleClick(event) {

    event.preventDefault();
    //history.push()
    console.info(event);
}

export const TitleMDR = () => {
    const classes = useStyles();

    return (
        <Route render={({ history }) => (
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" onClick={() => { history.push('/mdr/register-to-mdr') }} className={classes.link}>
                    <AddBoxIcon className={classes.icon} />
                    Register Collection
                </Link>
                <Link
                    color="inherit"
                    onClick={() => { history.push('/mdr/mdr') }}
                    className={classes.link}
                >
                    <BackupIcon className={classes.icon} />
                    Backup Metadata
                </Link>
                <Link
                    color="inherit" onClick={() => { history.push('/mdr/mdr-events') }} className={classes.link}
                >
                    <ListIcon className={classes.icon} />
                    Events
                </Link>

                <Link
                    color="inherit"
                    onClick={() => { history.push('/mdr/mdr-uri') }}
                    className={classes.link}
                >
                    <FolderIcon className={classes.icon} />
                    Fetch
                </Link>

            </Breadcrumbs >
        )} />
    );
}

/*
        { href: "/mdr/register-to-mdr", icon: "folder-close", text: "Register" },
        { href: "/mdr/mdr", icon: "home", text: "MDR" },
        { href: "/mdr/mdr-events", icon: "folder-close", text: "Events" },
        { href: "/mdr/mdr-uri", icon: "cube", text: "Fetch" }
*/