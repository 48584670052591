export const networkParams = {
  "0x1": {
    nftaddress: "",
    nftmarketaddress: "",
    treasuryaddress: "",
    simstokenaddress: "",
    currencySymbol: "ETH",
    chainId: 1,
    rpcUrls: [process.env.CHAINSTACK_API_ID],
    blockExplorerUrls: ["https://etherscan.io/"]
  },
  "0x3": {
    currencySymbol: "ETH",
    chainId: 3,
    blockExplorerUrls: "https://ropsten.etherscan.io/",
  },
  "0x4": {
    nftaddress: "0x457811342522595d6Bf0e038Ad679a56D8f241eB",//"0xb39497b0b52A4767e765d4d4b7e07a911300F108",
    nftmarketaddress: "0xc934Be4162d2E5bABB1bd8a184a5562ae33BFbEd",
    treasuryaddress: "0xBc7Fbd2cbB8e57ecfDe4f4666047e184399e9ab8",
    simstokenaddress: "0x8d92Ef33e5B8FF143362A067416B38853aFf53e7",
    currencySymbol: "ETH",
    chainId: 4,
    chainName: "Rinkeby",
    rpcUrls: [process.env.CHAINSTACK_API_ID],
    blockExplorerUrls: ["https://rinkeby.etherscan.io/address/"],
    blockExplorerUrlsTx: ["https://rinkeby.etherscan.io/address/"]
  },
  "0x2a": {
    currencySymbol: "ETH",
    chainId: 2,
    blockExplorerUrls: "https://kovan.etherscan.io/",
  },
  "0x5": {
    currencySymbol: "ETH",
    chainId: 5,
    blockExplorerUrls: "https://goerli.etherscan.io/",
  },
  "0x539": {
    chainName: "Local Chain",
    currencyName: "ETH",
    currencySymbol: "ETH",
    rpcUrl: "http://127.0.0.1:7545",
  },
  "0xa86a": {
    chainId: 43114,
    chainName: "Avalanche Mainnet",
    currencyName: "AVAX",
    currencySymbol: "AVAX",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    blockExplorerUrls: "https://cchain.explorer.avax.network/",
  },
  "0x38": {
    chainId: 56,
    chainName: "Smart Chain",
    currencyName: "BNB",
    currencySymbol: "BNB",
    rpcUrl: "https://bsc-dataseed.binance.org/",
    blockExplorerUrls: "https://bscscan.com/",
    wrapped: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  },
  "0x61": {
    chainId: 97,
    chainName: "Smart Chain - Testnet",
    currencyName: "BNB",
    currencySymbol: "BNB",
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    blockExplorerUrls: "https://testnet.bscscan.com/",
  },
  "0x7a69": {
    nftaddress: "",
    nftmarketaddress: "",
    treasuryaddress: "",
    simstokenaddress: "",
    name: "binance",
    color: "#92D9FA",
    chainId: 80001,
    price: 1,
    gasPrice: 1000000,
    rpcUrl: "",
    faucet: "",
    blockExplorerUrls: "",

  },
  "0x89": {
    name: "matic",
    color: "#2bbdf7",
    chainId: 137,
    price: 1,
    gasPrice: 10000000,
    rpcUrl: "https://rpc-mainnet.maticvigil.com",
    faucet: "https://faucet.matic.network/",
    blockExplorerUrls: "https://explorer-mainnet.maticvigil.com/",
  },
  "0x13881": {
    nftaddress: "0x0E08e1E9293CCa47793bF4D5d3F68414971BeCc6",
    mdraddress: "0xC7Ab6a1d2D0706300bC027d69964Ef3e537792Ad",
    nftmarketaddress: "",
    treasuryaddress: "",
    simstokenaddress: "",
    name: "mumbai",
    color: "#92D9FA",
    chainId: 80001,
    price: 1,
    gasPrice: 1000000,
    rpcUrl: "https://rpc-mumbai.maticvigil.com",
    faucet: "https://faucet.matic.network/",
    blockExplorerUrls: "https://mumbai.polygonscan.com/address/",
    blockExplorerUrlsTx: "https://mumbai.polygonscan.com/tx/",
  },
};

