import * as ROUTES from "../../configs/Routes";
import {
    Alignment,
    AnchorButton,
    Classes,
    Button,
    H5,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
} from "@blueprintjs/core";
import { React } from "react";
import { Route } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ListIcon from '@material-ui/icons/List';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import FolderIcon from '@material-ui/icons/Folder';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));


export const TitleMint = () => {
    const classes = useStyles();

    return (
        <Route render={({ history }) => (
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" onClick={() => { history.push('/drop/mint') }} className={classes.link}>
                    <AddBoxIcon className={classes.icon} />
                    Create NFT
                </Link>
                <Link
                    color="inherit" onClick={() => { history.push('/drop/events') }} className={classes.link}
                >
                    <ListIcon className={classes.icon} />
                    Events
                </Link>
                <Link
                    color="inherit"
                    onClick={() => { history.push('/drop/library') }}
                    className={classes.link}
                >
                    <PhotoLibraryIcon className={classes.icon} />
                    Library
                </Link>
                <Link
                    color="inherit"
                    onClick={() => { history.push('/drop/uri') }}
                    className={classes.link}
                >
                    <FolderIcon className={classes.icon} />
                    Fetch
                </Link>
                <Link
                    color="inherit"
                    onClick={() => { history.push('/drop/role') }}
                    className={classes.link}
                >
                    <LockOpenIcon className={classes.icon} />
                    Authorize
                </Link>
            </Breadcrumbs >
        )} />
    );
}
