import React from "react";
//import cx from "classnames";
import { Typography, Link } from '@material-ui/core';
import styles from "./style.module.css";
import blocks_platform from "../../assets/images/blocks_platform.png";
import blocks_logo from "../../assets/images/Blocks-SDK-logo-h-Blue.png";


export const SDK = props => {
    React.useEffect(() => {
        document.title = `Simba Blocks SDK`;

    });
    return (
        <div className={styles.container}>

            <Typography className={styles.h4_title} variant="h4" >
                <img className={styles.item_logo} src={blocks_logo} />
            </Typography>

            <Typography className={styles.h5_title} variant="h5" >
                <br></br>
                Overview
                <br></br>
            </Typography>
            <Typography className={styles.text} >
                libsimba.py-platform is a library simplifying the use of SIMBA Blocks APIs.  We aim to abstract away the various blockchain concepts, reducing the necessary time needed to get to working code.
                <br></br>
                <a href="https://github.com/SIMBAChain/libsimba.py-platform" target="_blank" rel="noreferrer"><u>Click Here for the SDK</u></a>

            </Typography>
            <Typography className={styles.h5_title} variant="h5" >
                <br></br>
                Sample NFT SDK Notebook
            </Typography>
            <Typography>

                <iframe src="https://nft.simbachaindemo.com:8855/notebooks/simba-nft/simba-nft.ipynb" width="90%" height="500"> </iframe>
            </Typography>


        </div>

    );
};


