/* eslint-disable no-unused-vars */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './core/store/configureStore'
import App from './containers/App/App'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './styles/theme';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAJxQYgF6qaL-w93DDh1m8yl4O5ExL3As8",
    authDomain: "drop-frontend.firebaseapp.com",
    projectId: "drop-frontend",
    storageBucket: "drop-frontend.appspot.com",
    messagingSenderId: "533929011777",
    appId: "1:533929011777:web:09c77a24c75ee61e9634c0",
    measurementId: "G-X6PFBGY8FK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const store = configureStore()

ReactDOM.render(

    <Provider store={store}>
        <App />
    </Provider>,

    document.getElementById('root')
)
