import React, { useEffect } from 'react'
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux'
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { TitleMint } from "../../components/TitleMint"
import constants from '../../configs/constants';
import { loadLibrary } from "../../core/interactions_blocks";
const uiStyles = () => ({
  card: {
    width: 300,
    margin: 10,
    display: 'inline-block',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  content: {
    height: 60
  },
  description: {
    textOverflow: 'ellipsis',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
});

const LibraryView = ({ classes }) => {
  const libraryItems = useSelector(state => state.connection.libraryItems);
  const dispatch = useDispatch();
  useEffect(() => {
    loadLibrary(dispatch, constants.NFT_DROP_CONTRACT_NAME, constants.NFT_DROP_EVENT_NAME);
  }, [dispatch]);

  return (
    <div className="container"  >
      <Grid container
        direction="column"
      >
        <Grid direction="column" >
          <TitleMint></TitleMint>
        </Grid>

        <Grid direction="column" >
          {libraryItems && libraryItems.length > 0 && libraryItems.reverse().map((item, index) => <Card key={index} className={classes.card}>
            <CardHeader
              title={item.name}
            />
            <CardMedia
              className={classes.media}
              image={item.image}
              title={item.name}
            />
            <CardContent className={classes.content}>
              <Typography component="p" className={classes.description}>
                {item.description}
              </Typography>
            </CardContent>
          </Card>)}
          {libraryItems.length === 0 && <div style={{marginTop: 20}}><CircularProgress /></div>}
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(uiStyles)(LibraryView)
