import { combineReducers } from 'redux'
import { uiReducer } from './reducer-ui'
import { connectionReducer } from './reducer-connection'
const rootReducer = combineReducers({
  connection: connectionReducer,
  ui: uiReducer,

})

export default rootReducer
