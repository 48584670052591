import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { loadMDREvents } from '../../core/interactions/interactions';
import { styles } from './styles.scss'
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import { TitleMDR } from "../../components/TitleMDR"


const uiStyles = () => ({
  root: {
    margin: 10,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

const MDREvents = ({ classes }) => {
  //const contract_read_only_instance = useSelector(state => state.connection.contract_read_only_instance);
  const mdr_contract = useSelector(state => state.connection.mdr_contract);
  const mdrevents = useSelector(state => state.connection.mdrevents);
  const chainID = useSelector(state => state.connection.chainID);
  const [user_event, setEvent] = useState('CollectionRegistered');
  const mdrblockexplorerurl = useSelector(state => state.connection.mdrblockexplorerurl);
  console.log(mdrblockexplorerurl)
  const event_on_page_load = 'CollectionRegistered'
  const block_number = useSelector(state => state.connection.block_number);
  const dispatch = useDispatch();
  useEffect(() => {

    loadMDREvents(dispatch, mdr_contract, chainID, block_number, event_on_page_load);
  }, [dispatch, mdr_contract, chainID, block_number]);

  const handleFetchClick = (event) => {
    setEvent(event.target.value);
    debugger
    console.log('user_event', event.target.value)
    if (event.target.value === 'CollectionRegistered') {
      loadMDREvents(dispatch, mdr_contract, chainID, block_number, event.target.value);
    } else if (event.target.value === 'MetadataSubmitted') {
    } else if (event.target.value === 'BatchMetadataSubmitted') {
    } else if (event.target.value === 'MetadataEdited') {

    } else { }

  }

  return (
    <div className="container"  >
      <Grid container
        direction="column"
      >
        <Grid direction="column">
          <TitleMDR />
        </Grid>

        <Grid direction="column" >
          <br></br>
          <br></br>

          <a href={mdrblockexplorerurl} target="_blank" rel="noreferrer"><u>Click Here for Block Explorer</u></a>
          <br></br>
          <br></br>
          <Button
            onClick={handleFetchClick}
            variant="contained"
            style={{
              backgroundColor: "#e7e9ea",
            }}>
            Fetch on-Chain Events
          </Button>
        </Grid>
        <Grid container direction="column" >
          <Paper >

            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>Event</TableCell>
                  <TableCell align="center">Owner</TableCell>
                  <TableCell align="center">Block Number</TableCell>
                  <TableCell align="center">Block Hash</TableCell>
                  <TableCell align="center">Registered Collection</TableCell>
                  <TableCell align="center">Metadata Updatable</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mdrevents && mdrevents.reverse().map((row, index) => (
                  < TableRow key={index} >
                    <TableCell >{row.event}</TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.args.CollectionOwner}
                    </TableCell>
                    <TableCell align="center">{row.blockNumber}</TableCell>
                    <TableCell align="center">{row.blockHash}</TableCell>
                    <TableCell align="center">{row.args.tokenCollection}</TableCell>
                    <TableCell align="center">{JSON.stringify(row.args.holdersCanSubmit)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
export default withStyles(uiStyles)(MDREvents)