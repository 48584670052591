import { create as ipfsHttpClient } from 'ipfs-http-client'
import React, { useState, useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '../../components/Alert'
import constants from '../../configs/constants'
import { styles } from './styles.scss'
import { mintNFT } from "../../core/interactions_blocks";
import { TitleMint } from "../../components/TitleMint"
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from '@material-ui/icons/Close';
import { Add, Fab, Slide, Button, Snackbar, Icon, Dialog, Grid, Table, TableBody, Toolbar, IconButton, TableCell, TableHead, TableRow, TextField, Paper, Typography } from "@material-ui/core";
const client = ipfsHttpClient('https://ipfs.infura.io:5001')

function TransitionComponent(props) {
  return <Slide direction="left" {...props} mountOnEnter unmountOnExit />
}
const MintView = ({ classes }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [metaDataName, setMetaDataName] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [mined, setMined] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const nft_contract = useSelector(state => state.connection.nft_contract);
  const account = useSelector(state => state.connection.account);
  const blockexplorerTx = useSelector(state => state.connection.blockexplorerTx);
  const modalState = useSelector(state => state.ui.modalState.openModal);

  const handleCreateNFT = useCallback(async (asset) => {
    asset.preventDefault();
    setShowSign(true);
    setShowDialog(true);
    setMined(false);
    try {
      const added = await client.add(
        selectedFile,
        {
          progress: (prog) => console.log(`received: ${prog}`)
        }
      )
      const url = `https://ipfs.infura.io/ipfs/${added.path}`
      console.log('New IPFS URL', url);
      const res = await mintNFT(constants.NFT_DROP_CONTRACT_NAME, account, selectedFile, description, url, metaDataName);
      setMined(true);
      console.log(res)
      setTransactionHash(res.transaction_hash);
    } catch (error) {
      console.log('Error mint file: ', error);
    }
  }, [account, selectedFile, description, metaDataName]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowDialog(false);
    setShowSign(false);
    setMined(false);
  };

  const SentAlert = (e) => {
    return <Snackbar open={showDialog} onClose={handleClose}>
      <Alert onClose={handleClose} severity="info">Please wait while we confirm your request...</Alert>
    </Snackbar>
  }

  const SuccessAlert = (e) => {

    return <Snackbar open={mined} autoHideDuration={10000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        Your request has been approved and is on the blockchain.<br />
        <a target="_blank" href={blockexplorerTx + transactionHash}>View on a block explorer</a>
      </Alert>
    </Snackbar>
  }

  return <div className="container"  >
    <Grid container
      direction="column">
      <Grid direction="column" >
        <TitleMint></TitleMint>
      </Grid>
      <Grid direction="column" >
        <form className="form">
          <Grid justifyContent="space-between" direction="column">
            <TextField
              style={{ width: '100%' }}
              label="Please enter metadata name"
              className="textField"
              inputProps={{ maxLength: 32 }}
              value={metaDataName}
              onChange={e => setMetaDataName(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid justifyContent="space-between" direction="column">
            <TextField
              style={{ width: '100%' }}
              label="Please enter description"
              className="textField"
              multiline
              maxRows={4}
              inputProps={{ maxLength: 128 }}
              value={description}
              onChange={e => setDescription(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item xs container direction="column" >
            <Button style={{
              backgroundColor: "#e7e9ea",
            }} variant="contained" component="label" >
              {" "}
              <AddIcon /> Upload a file
              <input type="file" hidden name="NFT"
                onChange={(e) => setSelectedFile(e.target.files[0])} />
            </Button>
          </Grid>
          <Grid item xs container direction="column" >
            <Button
              onClick={handleCreateNFT}
              disabled={!selectedFile || !metaDataName || !description}
              variant="contained"
              style={{
                backgroundColor: "#e7e9ea",
              }}
              className="my-4">
              Create NFT
            </Button>
          </Grid>
        </form>
      </Grid>

      <SentAlert />
      <SuccessAlert />
    </Grid>
  </div>;
}

export default withStyles(styles)(MintView)
