import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { loadPastEvents } from '../../core/interactions_blocks';
import constants from '../../configs/constants';
import { TitleMint } from "../../components/TitleMint"

const uiStyles = () => ({
  root: {
    margin: 10,
    overflowX: 'auto',
  },
  table: {
    minWidth: 50,
  },
});

const Web3Events = ({ classes }) => {
  const events = useSelector(state => state.connection.events);
  const blockexplorerurl = useSelector(state => state.connection.blockexplorerurl);
  const dispatch = useDispatch();
  useEffect(() => {
    loadPastEvents(dispatch, constants.NFT_DROP_CONTRACT_NAME, constants.NFT_DROP_EVENT_NAME);
  }, [dispatch]);

  const handleFetchClick = useCallback(() => {
    loadPastEvents(dispatch, constants.NFT_DROP_CONTRACT_NAME, constants.NFT_DROP_EVENT_NAME);
  }, [dispatch]);

  return (
    <div className="container"  >
      <Grid container
        direction="column"
      >
        <Grid direction="column">
          <TitleMint></TitleMint>
        </Grid>

        <Grid direction="column" >
          <br></br>
          <br></br>
          <a href={blockexplorerurl} target="_blank" rel="noreferrer"><u>Click Here for Block Explorer</u></a>
          <br></br>
          <br></br>
          <Button
            onClick={handleFetchClick}
            variant="contained"
            style={{
              backgroundColor: "#e7e9ea",
            }}>
            Fetch on-Chain Events
          </Button>
        </Grid>
        <Grid item xs={8} container direction="column" >
          <Paper >
            {events && <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>Address</TableCell>
                  <TableCell align="center">Date Created</TableCell>
                  <TableCell align="center">Token Id (Newest to Oldest)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.inputs.to}
                    </TableCell>
                    <TableCell align="center">{row.created_on}</TableCell>
                    <TableCell align="center">{row.inputs.tokenId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(uiStyles)(Web3Events)
