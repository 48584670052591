import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import { styles } from './styles.scss'
export const Button = () => {
    return (
        <MuiButton style={{
            backgroundColor: "#081a2b",
            color: "#ffffff"
        }} variant="contained" className=" icon">
        </MuiButton>
    );
}
