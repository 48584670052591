import axios from "axios";
import { getString } from '../libs/lib-asset-helpers';
import { eventLoaded, tokenURILoaded, libraryLoaded } from "../actions/actions-connection";
import { ethers } from 'ethers';

const API_URL = 'https://us-central1-nft-drop-cloud-functions.cloudfunctions.net/v2/apps/contract/';
const API_APP_NAME = 'nft-block';
const MINTER_ROLE = '0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6';
const API_OPTIONS = {
  headers: {
    'Content-Type': 'application/json'
  }
}

const keccak256 = require('keccak256');
function convert_to_bytes32_array(string, length) {
  let bytes32_arr = []
  let bytes = Buffer.alloc(32 * length)
  bytes.write(string)
  for (let i = 0; i < length; i++) {
    let part = Buffer.alloc(32)
    bytes.copy(part, 0, 32 * i, 32 + 32 * i)
    bytes32_arr.push('0x' + part.toString('hex'))
  }
  return bytes32_arr
}

// export const getMintRole = async () => {
//   let url = `${API_URL}?app_name=${API_APP_NAME}&contract_name=${API_CONTRACT_NAME}&method_name=MINTER_ROLE`;
//   const response = await axios.get(url, API_OPTIONS);
//   return response.data;
// }

export const setMinter = async (contractName, account) => {
  // const minterRole = await contract.MINTER_ROLE();
  const url = `${API_URL}?app_name=${API_APP_NAME}&contract_name=${contractName}&method_name=grantRole`

  try {
    const response = await axios.post(url, { account, role: MINTER_ROLE }, API_OPTIONS);
    return response.data;
  } catch (error) {
    console.log('Error calling grantRole: ', error);
  }
}

export const mintNFT = async (contractName, to, file, desc, fileUrl, metadataName) => {
  const fileString = await getString(file)

  const url = `${API_URL}?app_name=${API_APP_NAME}&contract_name=${contractName}&method_name=mint`;
  const params = {
    contentHash: '0x' + keccak256(fileString).toString('hex'),
    description: convert_to_bytes32_array(desc, 4),
    hashFunction: 27,
    image: convert_to_bytes32_array(fileUrl, 4),
    name: ethers.utils.formatBytes32String(metadataName),
    to
  };
  try {
    const response = await axios.post(url, params, API_OPTIONS);
    return response.data;
  } catch (error) {
    console.log('Error calling mint: ', error);
  }
}


export const loadLibrary = async (dispatch, contractName, eventName) => {
  const events = await loadPastEvents(dispatch, contractName, eventName);
  const items = await Promise.all((events || []).map(async event => {
    try {
      const tokenURIRes = await getTokenURI(contractName, event.inputs.tokenId);
      if (tokenURIRes.value) {
        const infoJsonString = decodeURIComponent(tokenURIRes.value).split("data:application/json;base64,")[1];
        var infoJson = Buffer.from(infoJsonString.toString(), 'base64').toString('ascii');
        const info = JSON.parse(infoJson);
        info.image = decodeURIComponent(info.image);
    
        let item = {
          ...info,
          tokenId: event.inputs.tokenId,
          tokenURI: tokenURIRes.value
        }
        return item
      } else {
        console.log('getTokenURI Endpoint', `${API_URL}?app_name=${API_APP_NAME}&contract_name=${contractName}&method_name=tokenURI&tokenId=${event.inputs.tokenId}`);
        console.log('getTokenURI error:', tokenURIRes);
        return null;
      }
    } catch (e) {
      console.log('error:', e);
      return null;
    }
  }));
  const filteredItems = items.filter(item => !!item);
  dispatch(libraryLoaded(filteredItems));
  return items;
}
export const loadPastEvents = async (dispatch, contractName, eventName) => {
  const url = `${API_URL}events/?app_name=${API_APP_NAME}&contract_name=${contractName}&event_name=${eventName}`;
  try {
    const response = await fetch(url, API_OPTIONS);
    const responseStr = await response.text();
    const jsonValue = JSON.parse(responseStr.replace(/"tokenId":(\d+)}/g, '"tokenId":"$1"}'));
    dispatch(eventLoaded(jsonValue.results));
    return jsonValue.results
  } catch (error) {
    console.log('Error loadPastEvents: ', error);
  }
}
export const getTokenURI = async (contractName, tokenId) => {
  const url = `${API_URL}?app_name=${API_APP_NAME}&contract_name=${contractName}&method_name=tokenURI&tokenId=${tokenId}`
  try {
    const response = await axios.get(url, API_OPTIONS);
    return response.data;

  } catch (error) {
    console.log('Error calling tokenURI: ', error);
  }
}
export const updateImage = async (contractName, tokenId, fileUrl) => {
  const image = await convert_to_bytes32_array(fileUrl, 4)
  const url = `${API_URL}?app_name=${API_APP_NAME}&contract_name=${contractName}&method_name=updateImage`;
  const params = {
    image,
    tokenId
  }
  try {
    const response = await axios.post(url, params, API_OPTIONS);
    return response.data;
  } catch (error) {
    console.log('Error calling updateImage: ', error);
  }
}