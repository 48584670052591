/* eslint-disable no-unused-vars */
import { ethers } from 'ethers';
import { registerCollectionToMDR } from "../../core/interactions/interactions";
import React, { useState, useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import { styles } from './styles.scss'
import { TitleMDR } from "../../components/TitleMDR"
import { Alert } from '../../components/Alert'
import { Add, Fab, Slide, MenuItem, Button, Snackbar, Icon, Dialog, Grid, Table, TableBody, Toolbar, IconButton, TableCell, TableHead, TableRow, TextField, Paper, Typography } from "@material-ui/core";

const holderOptions = [
  {
    value: true,
    label: 'YES',
  },
  {
    value: false,
    label: 'NO',
  },
];

const RegisterCollectionMDRView = ({ classes }) => {
  const dispatch = useDispatch();
  const [collectionAddress, setCollectionAddress] = useState('');
  const [holdersCanSubmit, setHoldersCanSubmit] = useState(false);
  const mdr_contract = useSelector(state => state.connection.mdr_contract);
  const mdraddress = useSelector(state => state.connection.mdraddress);
  const account = useSelector(state => state.connection.account);
  const provider = useSelector(state => state.connection.provider);
  const [showDialog, setShowDialog] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [mined, setMined] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const blockexplorerTx = useSelector(state => state.connection.blockexplorerTx);

  const handleMDRRegistration = useCallback(async (asset) => {
    asset.preventDefault();
    setShowSign(true);
    setShowDialog(true);
    setMined(false);

    try {
      const res = await registerCollectionToMDR(dispatch, mdr_contract, collectionAddress, account, holdersCanSubmit)
      //await res.wait();
      setMined(true);
      setTransactionHash(res.hash);
      console.log(res.hash)
    } catch (error) {
      console.log('Error uploading file: ', error);
    }

  }, [mdr_contract, dispatch, collectionAddress, account, holdersCanSubmit]);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowDialog(false);
    setShowSign(false);
    setMined(false);
  };

  const SentAlert = (e) => {
    return <Snackbar open={showDialog} onClose={handleClose}>
      <Alert onClose={handleClose} severity="info">Please wait while we confirm your request...</Alert>
    </Snackbar>
  }

  const SuccessAlert = (e) => {

    return <Snackbar open={mined} autoHideDuration={10000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        Your request has been approved and is on the blockchain.<br />
        <a target="_blank" href={blockexplorerTx + transactionHash}>View on a block explorer</a>
      </Alert>
    </Snackbar>
  }

  return <div className="container" >
    <Grid container
      direction="column">
      <Grid direction="column" >
        <TitleMDR></TitleMDR>
      </Grid>
      <Grid direction="column" >
        <form className="form">
          <Grid justifyContent="space-between" direction="column">
            <TextField
              style={{ width: '100%' }}
              label="Please enter NFT collection address"
              className="textField"
              inputProps={{ maxLength: 42 }}
              value={collectionAddress}
              onChange={e => setCollectionAddress(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid justifyContent="space-between" direction="column">
            <TextField
              style={{ width: '100%' }}
              label="Can NFT holders submit metadata to MDR?"
              select
              className="textField"
              value={holdersCanSubmit}
              onChange={e => setHoldersCanSubmit(e.target.value)}
              margin="normal"
            >
              {holderOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs container direction="column" >
            <Button
              onClick={handleMDRRegistration}
              disabled={!collectionAddress}
              variant="contained"
              style={{
                backgroundColor: "#e7e9ea",
              }}>
              Register Collection
            </Button>
          </Grid>

        </form>
      </Grid >
      <SentAlert />
      <SuccessAlert />
    </Grid >
  </div >;
}

export default withStyles(styles)(RegisterCollectionMDRView)